import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { SnackbarService } from '../services/snackbar.service';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  private formSubmitAttempt: boolean;

  today: number = Date.now();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private snackbarService: SnackbarService,
    private authService: AuthService,
    public dialog: MatDialog 
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  onSubmit() {
    if (this.form.valid) {
      this.authService.login(this.form.value).subscribe( 
        (shop) => {
          console.log(shop);
          if(shop.OK == "YES"){
            this.authService.loggedIn.next(true);
            console.log(shop.validated)
            if(shop.validated == "1"){
              console.log(shop.validated)
             // localStorage.setItem('validation', 'true')
              this.authService.validatedShop.next(true);
            }else{
              //localStorage.setItem('validation','false');
            }
            localStorage.setItem('loggedIn', 'true');
            localStorage.setItem('profile_id', shop.id);
            this.router.navigate(['profile']);
          }else{
            this.snackbarService.warn("Fejl på e-mail eller adgangskode")
          }
          
        }, err => {
	      console.log(err);
        }
      );
    }
  }

  openForgetPasswordForm() {
    this.dialog.open(ResetPasswordComponent, {width: '350px'});
  }


  
}