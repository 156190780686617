import {MediaMatcher} from '@angular/cdk/layout';
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { ShopService } from '../services/shop.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy, OnInit {

  isLoggedIn$: Observable<boolean>;
  //isValidatedShop$: Observable<boolean>;
  mobileQuery: MediaQueryList;
  id: string 
  private _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private authService: AuthService, private shopService: ShopService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener
      );
  }

  ngOnInit(){
    this.isLoggedIn$ = this.authService.isLoggedIn;
    /*this.id = localStorage.getItem("profile_id")
    this.shopService.isShopValidated(this.id).subscribe(data=>{
      if(data.validated == "1"){
        this.authService.validatedShop.next(true);
        this.isValidatedShop$ = this.authService.isValidatedShop;
      }
    })*/
      
  
    
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  onLogout() {
    this.authService.logout();
  }
}
