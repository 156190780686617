<mat-dialog-content [formGroup]="form" class="example-form">

    <img mat-card-image class="image-thumbnail" [src]="imagePath">

    <mat-form-field appearance="outline" class="full-width">
            <mat-label>Navn</mat-label>
    <input matInput placeholder="Navn" formControlName="name" readonly> 
</mat-form-field>

<mat-form-field appearance="outline" class="full-width">
        <mat-label>Phone</mat-label>
    <input matInput placeholder="Mobilnummer" formControlName="phone" readonly>
</mat-form-field>

<mat-form-field appearance="outline" class="full-width">
    <mat-label>Email</mat-label>
<input matInput placeholder="email" formControlName="email">
</mat-form-field>

<mat-form-field appearance="outline" class="full-width">
    <mat-label>Rolle</mat-label>
    <mat-select formControlName="job_title">
      <mat-option *ngFor="let jobtitle of jobtitles" [value]="jobtitle.value">
        {{jobtitle.value}}
      </mat-option>
    </mat-select>
  </mat-form-field>
<mat-slide-toggle formControlName="shop_authorized">Bekræft - at denne person kan træffe beslutninger for denne butik </mat-slide-toggle>
<!-- <mat-form-field>

    <mat-select placeholder="Select status"
        formControlName="status">

        <mat-option value="draft">
            Draft</mat-option>
        <mat-option value="published">
            Published</mat-option>
        <mat-option value="deleted">
            Deleted</mat-option>

    </mat-select>

</mat-form-field>

<mat-form-field>

        <mat-select placeholder="Select economy"
        formControlName="economy">

        <mat-option value="free">
            Free</mat-option>
        <mat-option value="invoice">
            Invoice</mat-option>
        <mat-option value="paid">
            Paid</mat-option>

    </mat-select>


</mat-form-field>-->

</mat-dialog-content>

<mat-dialog-actions>

<button class="mat-raised-button"
        (click)="close()">
    Luk
</button>
<span class="fill-space"></span>
<button class="mat-raised-button mat-primary"
        (click)="save()" [disabled]="form.invalid">
    Gem
</button>



</mat-dialog-actions>
