import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Shop } from '../shared/shop';

interface responseOk{
  OK: string
}

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  constructor(private http: HttpClient) { }

  getShopById(id: string): Observable<Shop>{
    let httpParams = new HttpParams()
                  .set("id",id);
    return this.http.get<Shop>('server/engros_shop.php?action=read', {params: httpParams});
  }

  isShopValidated(id: string): Observable<Shop>{
    let httpParams = new HttpParams()
                  .set("id",id);
    return this.http.get<Shop>('server/engros_shop.php?action=is_this_shop_validated', {params: httpParams});
  }

  updateShop(shop: Shop): Observable<Shop>{
    let formData: FormData = new FormData()
    formData.append("id", shop.id)
    formData.append("name",shop.name)
    formData.append("cvr", shop.cvr)
    formData.append("address",shop.address)
    formData.append("zip", shop.zip)
    formData.append("city", shop.city)
    formData.append("country",shop.country)
    formData.append("phone", shop.phone)
    formData.append("web", shop.web)
    formData.append("email", shop.email)
    formData.append("contact_name",shop.contact_name)
    formData.append("contact_phone", shop.contact_phone)
    formData.append("contact_email", shop.contact_email)
    formData.append("validated", shop.validated)
    formData.append("notes", shop.notes)
    formData.append("action", "update");
    return this.http.post<Shop>('server/engros_shop.php', formData);
  }

  updateLogoImage(id: string, fileToUpload: File): Observable<responseOk>{
    const formData: FormData = new FormData();
    formData.append('userfile', fileToUpload);
    formData.append('id', id);
    return this.http.post<responseOk>('server/engros_shop.php?action=add_logo', formData)
  }
}
