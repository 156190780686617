import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { SnackbarService } from '../services/snackbar.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  private formSubmitAttempt: boolean;

  formErrors = {
    'email': ''
  }

  validationMessages = {
    'email':{
      'required' : 'Der skal indtastes email',
      'email': 'Email skal være gyldigt emailformat.'
    },
  }

 constructor(
    public dialogRef: MatDialogRef<ResetPasswordComponent>, 
    private fb: FormBuilder,
    private authService: AuthService,
    private snackbarService: SnackbarService) { 
      this.createForm();
  }

  createForm(){
      this.resetPasswordForm = this.fb.group({
        email: ['', [Validators.required, Validators.email] ]
      });
      this.resetPasswordForm.valueChanges
      .subscribe(data => this.onValueChanged(data));
    
    this.onValueChanged();
  }

  onValueChanged(data?: any){
    if(!this.resetPasswordForm){
      return;
    }
    const form = this.resetPasswordForm;
    for(const field in this.formErrors){
      if(this.formErrors.hasOwnProperty(field)){
        //clear previous eroor message(if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if(control && control.dirty && !control.valid){
          const messages = this.validationMessages[field];
          for(const key in control.errors){
            if(control.errors.hasOwnProperty(key)){
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }

  }

  ngOnInit() {
  }

  onSubmit() {
    this.authService.resetPassword(this.resetPasswordForm.value.email).subscribe(
      data => {
        if(data.OK == "YES"){
          this.snackbarService.success('Succes, vær venlig at tjekke din email!')
        }else{
          this.snackbarService.warn(data.OK)
        }
      }
    )
    //this.dialogRef.close();
  }

}
