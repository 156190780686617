import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Employee } from '../shared/employee';

interface responseData{
  OK: string,
  employee_list: Employee[]
}

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(private http: HttpClient) { }

  getEmployeeByShopId(shop_id: string): Observable<responseData>{
    /*let httpParams = new HttpParams()
                  .set("shop_id",id);*/
    let formData: FormData = new FormData()
    formData.append("shop_id", shop_id)
    formData.append("action", "shop_employee_list")
    return this.http.post<responseData>('server/engros_shop_employee.php', formData);
  }

  addEmployee(employee: Employee): Observable<Employee>{
    let formData: FormData = new FormData()
    formData.append("first_name", employee.fname)
    formData.append("last_name", employee.lname)
    formData.append("dial_country_code", employee.country_code)
    formData.append("phone", employee.phone.replace(/\s/g, ""))
    formData.append("country", employee.country)
    formData.append("locale", employee.locale)
    formData.append("gender", employee.gender)
    formData.append("email", employee.email)
    formData.append("shop_id", employee.shop_id)
    formData.append("job_title",employee.job_title)
   // formData.set("job_title_confirmed", employee.job_title_confirmed)
   // formData.set("shop_id_confirmed", employee.shop_id_confirmed)
    formData.set("shop_authorized", employee.shop_authorized)
    formData.append("action", "shop_employee_create")
    return this.http.post<Employee>('server/engros_shop_employee.php', formData);
  }

  updateEmployeeStatus(employee: Employee): Observable<Employee>{
    let formData: FormData = new FormData()
    formData.append("app_user_shop_id", employee.app_user_shop_id)
    formData.append("job_title", employee.job_title)
    formData.append("email", employee.email)
    formData.append("shop_authorized", employee.shop_authorized)
    formData.append("action", "shop_employee_update")
    return this.http.post<Employee>('server/engros_shop_employee.php', formData);
  }

  rejectUser(app_user_shop_id:string) : Observable<Employee>{
    let formData: FormData = new FormData()
    formData.append("app_user_shop_id", app_user_shop_id)
    formData.append("action", "shop_employee_delete")
    return this.http.post<Employee>('server/engros_shop_employee.php', formData)
  }

 /* approveUser(id:string) : Observable<Employee>{
    let httpParams = new HttpParams()
                  .set("id",id);
    return this.http.get<Employee>('server/engros_shop_employee.php?action=confirm_shop_id', {params: httpParams})
  }
  authorizeUser(id:string) : Observable<Employee>{
    let httpParams = new HttpParams()
                  .set("id",id);
    return this.http.get<Employee>('server/engros_shop_employee.php?action=authorize_user', {params: httpParams})
  }

  unAuthorizeUser(id:string) : Observable<Employee>{
    let httpParams = new HttpParams()
                  .set("id",id);
    return this.http.get<Employee>('server/engros_shop_employee.php?action=unauthorize_user', {params: httpParams});
 
  }*/


}
