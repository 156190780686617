import { Component, OnInit } from '@angular/core';
import { EmployeeService } from '../services/employee.service';
import { SnackbarService } from '../services/snackbar.service';
import { Employee } from '../shared/employee';
import { MatSlideToggleChange } from '@angular/material';
import { MatTableDataSource } from '@angular/material';
import { DialogService } from '../services/dialog.service';
import { ShopService } from '../services/shop.service';
import {MatDialog, MatDialogConfig} from '@angular/material'
import {EmployeeDetailComponent} from '../employee-detail/employee-detail.component'
import { EmployeeAddComponent } from '../employee-add/employee-add.component';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {

  employees: Employee[];
  dataSource;
  id: string;
  hasData: boolean = false
  isValidate: boolean = true;

  displayedColumns: string[] = ['name',  'phone', 'email', 'authorize', 'delete'];


  constructor(
    private employeeService: EmployeeService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private dialogService: DialogService,
    private shopService: ShopService
    
  ) { }

  ngOnInit() {
    this.id = localStorage.getItem("profile_id")
    this.shopService.isShopValidated(this.id).subscribe(data=>{
      if(data.validated == "0"){
        this.isValidate = false
      }
    })
    
    this.employeeService.getEmployeeByShopId(this.id).subscribe(
      data => {
        console.log(data.employee_list.length)
        if(data.employee_list.length > 0)
          this.hasData = true
        this.employees = data.employee_list
        this.dataSource = new MatTableDataSource<Employee>(this.employees)
      }//.filter((offer: Offer) => offer.status != "deleted")
    )
  }

  createEmployee(): void{
    let dialogRef = this.dialog.open(EmployeeAddComponent, {
      width: '600px',
      data: 'Add News',
      disableClose: true 
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      console.log(result.data)
     this.employeeService.addEmployee(result.data).subscribe(
      (data) => {
        if(data.OK == "YES"){
          this.employeeService.getEmployeeByShopId(this.id).subscribe(
            (data) => {
              this.employees = data.employee_list
              this.dataSource = new MatTableDataSource<Employee>(this.employees)
              //this.snackbarService.success(this.translate.instant("add_success"))
              })
        }
      })
    });
  }

  editEmployee(employee: Employee){
    let dialogRef = this.dialog.open(EmployeeDetailComponent, {
      width: '600px',
      disableClose: true ,
      data: employee
    });

    /*dialogRef.afterClosed().subscribe(
      val => console.log("Dialog output:", val)
  );*/
  dialogRef.componentInstance.event.subscribe((result) => {
    console.log(result.data)
    this.employeeService.updateEmployeeStatus(result.data).subscribe(
    (data) => {
      if(data.OK == "YES"){
        this.employeeService.getEmployeeByShopId(this.id).subscribe(
        (data) => {this.employees = data.employee_list
        this.dataSource = new MatTableDataSource<Employee>(this.employees)
        }
        )}
    })
  });
  }

  /*confirmValidate(employee: Employee){
    console.log(employee)
    this.employeeService.approveUser(employee.id).subscribe((data)=>{
      if(data.OK == "YES"){
        employee.shop_id_confirmed = "1"
        this.employees.map((emp, i) => {
          if (emp.id == employee.id){
            this.employees[i] = employee;
          }
        });
      }
    })
    
  }*/

  delete(employee: Employee){
    this.dialogService.openConfirmDialog("Er du sikker på slette denne brugere?")
     .afterClosed().subscribe(res=>{
       if(res){
          this.employeeService.rejectUser(employee.app_user_shop_id).subscribe((data)=>{
            if(data.OK == "YES"){
              this.employees.forEach( (item, index) => {
                if(item.app_user_shop_id === employee.app_user_shop_id) 
                  this.employees.splice(index,1);
                this.dataSource = new MatTableDataSource<Employee>(this.employees);
              });
            }
          })
        }
      })
  }

  /*authorize(employee: Employee){
    this.employeeService.authorizeUser(employee.id).subscribe((data)=>{
      if(data.OK == "YES"){
        employee.shop_authorized = "1"
        this.employees.map((emp, i) => {
          if (emp.id == employee.id){
            this.employees[i] = employee;
          }
        });
      }
    })
  }

  deAuthorize(employee: Employee){
    this.employeeService.unAuthorizeUser(employee.id).subscribe((data)=>{
      if(data.OK == "YES"){
        employee.shop_authorized = "0"
        this.employees.map((emp, i) => {
          if (emp.id == employee.id){
            this.employees[i] = employee;
          }
        });
      }
    })
  }*/

  /*toggle(event: MatSlideToggleChange, employee: Employee) {
    console.log('toggle', event.checked);
    if(event.checked == false){
      //unauthorized
      this.deAuthorize(employee)
    }else{
      //authorized
      this.authorize(employee)
    }

  }*/

}
