import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import { Shop } from '../shared/shop';
import { ShopService } from '../services/shop.service';
import { SnackbarService } from '../services/snackbar.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-shop-detail',
  templateUrl: './shop-detail.component.html',
  styleUrls: ['./shop-detail.component.scss']
})
export class ShopDetailComponent implements OnInit {

  shopForm: FormGroup;
  submittingForm  : boolean=false;
  shop: Shop;
  id: string = localStorage.getItem("profile_id")
  imageUrl: string = "/assets/imgs/defaultImg.jpg"
  fileToUpload: File = null;
  imageClick : boolean = true;

  formErrors = {
    'name': '',
    'cvr' : '',
    'address' : '',
    'zip' : '',
    'city': '',
    'country': '',
    'phone': '',
    'contact_name':'',
    'contact_phone' : ''
  };

  validationMessages = {
    'name':{
      'required' : 'Der skal indtastes navn',
      'minlength' : 'Navnet skal være mindst 4 tegn'
    },
    'phone':{
      'required' : 'Der skal indtastes phonenummer',
      'pattern' : 'Telefonnummeret skal kun indeholde tal',
      'minlength' : 'Telefonnummeret skal være mindst 8 tegn'
    },
    'address':{
      'required' : 'Der skal indtastes adresse'
    },
    'city':{
      'required' : 'Der skal indtastes by'
    },
    'zip':{
      'required' : 'Der skal indtastes postalnummer',
      'pattern' : 'Postalnummer skal kun indeholde tal.'
    },
    'country':{
      'required' : 'Der skal indtastes land'
    },
    'contact_name':{
      'required' : 'Der skal indtastes kontaktperson navn'
    },
    'contact_phone':{
      'required' : 'Der skal indtastes kontaktperson mobilnummer',
      'pattern' : 'Telefonnummeret skal kun indeholde tal',
      'minlength' : 'Mobilnummer skal være mindst 8 tegn'
    }
  };

  constructor(
    private fb: FormBuilder,
    private shopService: ShopService,
    private snackbarService: SnackbarService,
    private authService: AuthService
  ) { 
  }

  createForm(){
    this.shopForm = this.fb.group({
      id: this.shop.id,
      name: [this.shop.name, [Validators.required, Validators.minLength(4)] ],
      cvr: [this.shop.cvr],
      phone: [this.shop.phone, [Validators.required, Validators.pattern, Validators.minLength(8)] ],
      address: [this.shop.address, [Validators.required] ],
      city: [this.shop.city, [Validators.required] ],
      zip: [this.shop.zip, [Validators.required, Validators.pattern] ],
      country: [this.shop.country, [Validators.required]],
      contact_name: [this.shop.contact_name, [Validators.required]],
      contact_phone: [this.shop.contact_phone, [Validators.required, Validators.pattern, Validators.minLength(8)]],
      web: this.shop.web,
      contact_email: this.shop.contact_email,
      email: this.shop.email,
      notes: this.shop.notes,
      validated: "1"

    });

    this.shopForm.valueChanges
      .subscribe(data => this.onValueChanged(data));
    
    this.onValueChanged(); //reset form validation messages
  }

  ngOnInit() {
    this.shopService.getShopById(this.id).subscribe(
      shop => {
        this.shop = shop;
        if(this.shop.logo_path !== ""){
          this.imageUrl = this.shop.logo_path
          this.imageClick = false;
         }

         this.createForm();
      }
    );
  }

  onFileChanged(file: FileList) {
  if(file){
    this.fileToUpload = file.item(0)
    //console.log(this.selectedFile)
    //show image preview
    var reader = new FileReader();
    reader.onload = (event:any) =>{
      this.imageUrl = event.target.result
    }
    reader.readAsDataURL(this.fileToUpload)
    this.shopService.updateLogoImage(this.shop.id.toString(), this.fileToUpload).subscribe(
      (data) => {
        //console.log(data)
        if(data.OK == "YES"){
          this.imageClick = false;
          //this.shop.logo_path = "https://likesharing.dk/database/engros/shop_logo/" + this.shop.id + ".jpg"
          //this.event.emit({data: this.form.value});
        }
      }
    )

    console.log(this.fileToUpload)
  }
}

  onValueChanged(data?: any){
    if(!this.shopForm){
      return;
    }
    const form = this.shopForm;
    for(const field in this.formErrors){
      if(this.formErrors.hasOwnProperty(field)){
        //clear previous eroor message(if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if(control && control.dirty && !control.valid){
          const messages = this.validationMessages[field];
          for(const key in control.errors){
            if(control.errors.hasOwnProperty(key)){
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }

  }


  onSubmit(){
    this.shopService.updateShop(this.shopForm.value).subscribe(
      data => {
        if(data.OK == "YES"){
          //this.authService.validatedShop.next(true);
          this.snackbarService.success('Updated Successfully!')
        }
      }
    )
  }


  updatePassword(){
    
  }

}
