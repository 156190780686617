<mat-dialog-content [formGroup]="form" class="example-form" >
  <h3>Opret ny bruger</h3>
  
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Fornavn</mat-label>
        <input matInput placeholder="Fornavn" formControlName="fname">
        <!--<mat-error *ngIf="isFieldInvalid('fname')">
                Der skal indtastes fornavn
        </mat-error>-->
    </mat-form-field>
  
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Efternavn</mat-label>
      <input matInput placeholder="Efternavn" formControlName="lname" >
      <!--<mat-error *ngIf="isFieldInvalid('lname')">
              Der skal indtastes efternavn
      </mat-error>-->
    </mat-form-field>
  
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Køn</mat-label>
      <mat-select formControlName="gender">
        <mat-option *ngFor="let gender of genders" [value]="gender.value" >
          <!-- {{gender.value | translate | titlecase}} -->
          {{gender.value | titlecase }}
        </mat-option>
      </mat-select>
      <!--<mat-error *ngIf="isFieldInvalid('gender')">
        Der skal vælge køn
      </mat-error>-->
    </mat-form-field>
  
    
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Landekode</mat-label>
      <mat-select formControlName="country_with_dial_code">
        <mat-option *ngFor="let code of countryCodes" [value]="code" >
          + {{code.value}}
        </mat-option>
      </mat-select>
      <!--<mat-error *ngIf="isFieldInvalid('country_code')">
        Der skal vælge landekode
      </mat-error>-->
  </mat-form-field>
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Mobilnummer</mat-label>
        <input matInput placeholder="Mobilnummer" formControlName="phone">
        <!--<mat-error *ngIf="isFieldInvalid('phone')">
                Der skal indtastes mobilnummer
        </mat-error>-->
    </mat-form-field>
  
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Email</mat-label>
        <input matInput placeholder="Email" formControlName="email" >
        <!--<mat-error *ngIf="isFieldInvalid('email')">
                Der skal indtastes email
        </mat-error>-->
    </mat-form-field>
 
    

    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Rolle</mat-label>
      <mat-select formControlName="job_title">
        <mat-option *ngFor="let jobtitle of jobtitles" [value]="jobtitle.value">
          {{jobtitle.value | titlecase}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!--<p>
    <label id="phone-type">Phone Type</label>
    <mat-radio-group aria-label="phone-type" class="example-radio-group" formControlName="device_type">
      <mat-radio-button class="example-radio-button" value="iOS">IOS</mat-radio-button>
      <mat-radio-button class="example-radio-button" value="Android">Android</mat-radio-button>
    </mat-radio-group>
  </p>-->
    
  <mat-slide-toggle formControlName="shop_authorized">Bekræft - at denne person kan træffe beslutninger for denne butik </mat-slide-toggle>
</mat-dialog-content>
            
<mat-dialog-actions>
  <button class="mat-raised-button"
          (click)="close()">
      Fortryd
  </button>
  <span class="fill-space"></span>
  <button class="mat-raised-button mat-primary"
          (click)="save()" >
      Gem
  </button>
</mat-dialog-actions>