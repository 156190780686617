<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="example-toolbar" *ngIf="isLoggedIn$ | async as isLoggedIn">
    <button mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
    <h1 class="example-app-name"> Velkommen til ShopEngros </h1>
  </mat-toolbar>

  <mat-sidenav-container class="example-sidenav-container"
                         [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'"
                 [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">

  <!--<mat-sidenav-container class="example-sidenav-container"
                         [style.marginTop.px]="mobileQuery.matches ? 75 : 0" >
    <mat-sidenav  opened #snav class="sidenav"
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches" fixedTopGap="75" > -->
      <mat-nav-list *ngIf="isLoggedIn$ | async as isLoggedIn">
        <!--<a mat-list-item routerLink="/home">Dashboard</a>
        <a mat-list-item [routerLink]="['profile', id]">Min Profil</a>-->
        <a mat-list-item routerLink="profile" 
        routerLinkActive
        #routerLinkActiveInstance="routerLinkActive"
        [class.list-item-active]="routerLinkActiveInstance.isActive">
        <mat-icon matListIcon class="app-nav-list-icon"> contacts </mat-icon> Profil </a>
        <a mat-list-item routerLink="employee"
        routerLinkActive
        #routerLinkActiveInstance="routerLinkActive"
        [class.list-item-active]="routerLinkActiveInstance.isActive">
        <mat-icon matListIcon class="app-nav-list-icon"> supervisor_account </mat-icon>Ansatte</a>

        <a mat-list-item (click)="onLogout()"
        routerLinkActive
        #routerLinkActiveInstance="routerLinkActive"
        [class.list-item-active]="routerLinkActiveInstance.isActive">
        <mat-icon matListIcon class="app-nav-list-icon"> logout </mat-icon>Logud</a>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>