<div fxLayout="column" fxFlex="80" fxFlexOffset="12" fxFlex.xs="auto" fxFlexOffset.xs="1" *ngIf='shop'>
  <mat-card class="example-card mat-elevation-z1" >
      <mat-card-header>
            
          <input style="display: none" [disabled]="!imageClick" type="file" (change)="onFileChanged($event.target.files)"
          #fileInput accept="image/*">
        <!--<mat-icon style="margin-left:auto; display: flex;" *ngIf="!imageClick" (click) = "deleteImage()">close</mat-icon>-->
        <img  mat-card-avatar [src]="imageUrl"  (click)="fileInput.click()">
            
          <!--<img mat-card-avatar src="https://material.angular.io/assets/img/examples/shiba2.jpg">--> 
          <span class="fill-space"></span>
         <!-- <button mat-raised-button (click)="updatePassword()">Skift adgangskode</button>-->
        </mat-card-header>
        <br>
        <mat-card-content>
            <form novalidate [formGroup]="shopForm" #fform="ngForm" (ngSubmit)="onSubmit()">
              
              <p>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Navn</mat-label>
                  <input matInput type="text" formControlName="name" required>
                  <mat-error *ngIf="formErrors.name">{{ formErrors.name }}</mat-error>
                </mat-form-field>
            
              </p>
              <p>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>CVR</mat-label>
                  <input matInput type="text" formControlName="cvr">
                  <mat-error *ngIf="formErrors.cvr">{{ formErrors.cvr }}</mat-error>
                </mat-form-field>
             
              </p>
              <p>
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Adresse</mat-label>
                <input matInput type="text" formControlName="address" required>
                <mat-error *ngIf="formErrors.address">{{ formErrors.address }}</mat-error>
              </mat-form-field>
            </p>
            <p fxLayout="row" fxLayout.xs="column" >
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Postnr</mat-label>
                <input matInput formControlName="zip" type="tel" pattern="[0-9]*" required> 
                <mat-error *ngIf="formErrors.zip">{{ formErrors.zip }}</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="full-width">
                  <mat-label>By</mat-label>
                  <input matInput type="text"formControlName="city" required>
                  <mat-error *ngIf="formErrors.city">{{ formErrors.city }}</mat-error>
                </mat-form-field>
              </p>
              <p>
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Hjemmeside</mat-label>
                <input matInput type="text" formControlName="web" >
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Land</mat-label>
                <input matInput type="text" formControlName="country" required>
                <mat-error *ngIf="formErrors.country">{{ formErrors.country }}</mat-error>
              </mat-form-field>
            </p>
            <p fxLayout="row" fxLayout.xs="column" >
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Telefon</mat-label>
                  <input matInput type="tel" pattern="[0-9]*" formControlName="phone" required>
                  <mat-error *ngIf="formErrors.phone">{{ formErrors.phone }}</mat-error>
                </mat-form-field>
                <span class="fill-space"></span>
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Email</mat-label>
                    <input matInput type="text" formControlName="email" required>
                  </mat-form-field>
                </p>
                <p>
                      <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Navn på kontaktperson</mat-label>
                        <input matInput type="text" formControlName="contact_name" required>
                        <mat-error *ngIf="formErrors.contact_name">{{ formErrors.contact_name }}</mat-error>
                      </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field appearance="outline" class="full-width">
                          <mat-label>Kontaktpersons email</mat-label>
                          <input matInput type="text" formControlName="contact_email">
                        </mat-form-field>
                      </p>
                      <p>
                          <mat-form-field appearance="outline" class="full-width">
                            <mat-label>Kontaktpersons mobilnummer</mat-label>
                            <input matInput type="tel" pattern="[0-9]*" formControlName="contact_phone" required>
                            <mat-error *ngIf="formErrors.contact_phone">{{ formErrors.contact_phone }}</mat-error>
                          </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field appearance="outline" class="full-width">
                              <mat-label>Note</mat-label>
                              <textarea matInput formControlName="notes">{{notes}}</textarea>
                            </mat-form-field>
                          </p>
                            <button type="submit" mat-raised-button color="accent" class="float-right" [disabled] = shopForm.invalid>Gem</button>
          </form>
          </mat-card-content>
        
        </mat-card>
</div>
