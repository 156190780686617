import { Component, EventEmitter, Inject, OnInit  } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material"
import { MatSlideToggleChange } from '@angular/material';
import {FormBuilder, Validators, FormGroup} from "@angular/forms"

import {Employee} from "../shared/employee"

@Component({
  selector: 'app-employee-detail',
  templateUrl: './employee-detail.component.html',
  styleUrls: ['./employee-detail.component.scss']
})
export class EmployeeDetailComponent implements OnInit {

  imagePath: String = "/assets/imgs/defaultImg.jpg"
  form: FormGroup
  jobtitles = [
    {value: 'Manager'},
    {value: 'Ejer'},
    {value: 'Ansat'}
  ]

  shopConfirmed: boolean = false

  public event: EventEmitter<any> = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EmployeeDetailComponent>,
    @Inject(MAT_DIALOG_DATA) employee:Employee ) {
      this.form = fb.group({
        app_user_shop_id: employee.app_user_shop_id,
        name: employee.name,
        phone: employee.phone,
        email: employee.email,
        //shop_id_confirmed: [employee.shop_id_confirmed === "1"?true:false, Validators.required],
        shop_authorized: [employee.shop_authorized === "1"?true:false, Validators.required],
        job_title: employee.job_title,
        photo_path: employee.photo_path,
       // job_title_confirmed: employee.job_title_confirmed === "1"?true:false
    });

    if(employee.photo_path !== ""){
      this.imagePath = employee.photo_path
     }

    /*if(employee.locale === "en"){
      this.jobtitles =[
        {value: 'Manager'},
        {value: 'Owner'},
        {value: 'Employee'}
      ]
    }else{
      this.jobtitles =[
        {value: 'Manager'},
        {value: 'Ejer'},
        {value: 'Ansat'}
      ]
    }*/
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched)
    );
  }

  ngOnInit() {
  }

  save() {
    console.log(this.form.value) 
    //this.form.value.job_title_confirmed = true
    /*if(this.form.value.shop_id_confirmed)
      this.form.value.job_title_confirmed = true
    else
      this.form.value.job_title_confirmed = false
    */
    this.event.emit({data: this.form.value});
    this.dialogRef.close();
  }

  /*toggle(event: MatSlideToggleChange) {
    console.log(event)
    console.log('toggle', event.checked);
    if(event.checked == false){
      this.shopConfirmed = false
      this.form.value.shop_authorized = false
      this.form.value.job_title_confirmed = false
      //unauthorized
      //this.deAuthorize(employee)
    }else{
     // this.form.value.job_title_confirmed = true
      this.shopConfirmed = true
      //authorized
     // this.authorize(employee)
    }

  }*/

  close() {
    this.dialogRef.close();
}

}
