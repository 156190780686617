import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Shop } from '../shared/shop';
import { Router } from '@angular/router';

interface isLoggedIn{
  OK : boolean
}
interface responseOK{
  OK: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  validatedShop: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private http:HttpClient
  ) {
    ///console.log("I am auth")
   }
  
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get isValidatedShop(){
    return this.validatedShop.asObservable();
  }

  isUserLoggedIn() : Observable<isLoggedIn>{
    return this.http.get<isLoggedIn>('server/engros_confirm_login.php?action=confirm_shop_login');
  }

  getPrivacy(locale: string) : Observable<Shop>{
    let httpParams = new HttpParams()
    .set("locale",locale)
    .set("action", "privacy");
    return this.http.get<Shop>('server/engros_shop.php', {params: httpParams});
  }

  signup(shop: Shop) : Observable<Shop>{
    let formData: FormData = new FormData()
    formData.append("name",shop.name)
    formData.append("cvr", shop.cvr)
    formData.append("address",shop.address)
    formData.append("zip", shop.zip)
    formData.append("city", shop.city)
    formData.append("country",shop.country)
    formData.append("phone", shop.phone.replace(/\s/g, ""))
    formData.append("web", shop.web)
    formData.append("email", shop.email.toLowerCase())
    formData.append("contact_name",shop.contact_name)
    formData.append("contact_email", shop.contact_email.toLowerCase())
    formData.append("contact_phone", shop.contact_phone.replace(/\s/g, ""))
    formData.append("notes", shop.notes)
    formData.append("locale", "da")
    formData.append("action", "create");
    //let options = new RequestOptions({ headers: httpHeaders,withCredentials:true});
    return this.http.post<Shop>('server/engros_shop.php', formData);
  }

  login(shop: Shop) : Observable<Shop>{
    let httpParams = new HttpParams()
                  .set("email",shop.email)
                  .set("password", shop.password)
                  .set("action", "search");
    //let options = new RequestOptions({ headers: httpHeaders,withCredentials:true});
    return this.http.get<Shop>('server/engros_shop.php', { params: httpParams});
  }

  logout() {
    return this.http.get('server/engros_shop.php?action=logout').subscribe(
      (data)=>{
        localStorage.removeItem('loggedIn');
        localStorage.removeItem('profile_id');
        this.loggedIn.next(false);
        this.router.navigate(['/login']);
      }
    );
   /* */
  }

  resetPassword(email: string): Observable<responseOK>{
    let httpParams = new HttpParams()
    .set("email", email)
    .set("action", "reset_password");
    return this.http.get<responseOK>('server/engros_shop.php', {params: httpParams});
  }


}
