import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppMaterialModule } from './app-material/app-material.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import 'hammerjs';

import { AppRoutingModule } from './app-routing/app-routing.module';

import { AuthGuard } from './shared/auth.guard';
import { AuthService } from './services/auth.service';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent, DialogSignupPrivacy } from './signup/signup.component';
import {ShopDetailComponent} from './shop-detail/shop-detail.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { EmployeeComponent } from './employee/employee.component';
import { MatConfirmDialogComponent } from './mat-confirm-dialog/mat-confirm-dialog.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { EmployeeDetailComponent } from './employee-detail/employee-detail.component';
import { EmployeeAddComponent } from './employee-add/employee-add.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    DialogSignupPrivacy,
    ShopDetailComponent,
    HeaderComponent,
    HomeComponent,
    EmployeeComponent,
    MatConfirmDialogComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    EmployeeDetailComponent,
    EmployeeAddComponent
  ],
  imports: [
    AppMaterialModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [ HttpClient ]
      }
    }),
  ],
  entryComponents: [
    EmployeeDetailComponent,
    MatConfirmDialogComponent,
    ResetPasswordComponent,
    EmployeeAddComponent,
    DialogSignupPrivacy
],
  providers: [  
    AuthService, 
    AuthGuard, ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(translate: TranslateService) {
    // 'en-GB' -> 'en'
    const browserLang = translate.getBrowserLang();
    console.log(browserLang)
    translate.use(browserLang.match(/en|da/) ? browserLang : 'da');
  }
 }
 // AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
