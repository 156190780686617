<div fxLayout="column" fxFlex="80" fxFlexOffset="8" fxFlex.xs="auto" fxFlexOffset.xs="1">
    <h3>{{'ansatte' | titlecase}}</h3>
    <div fxLayout="column wrap" fxLayoutAlign="start start" class="card-container">
      <button button ="submit" mat-mini-fab color="primary" (click) = "createEmployee()">
        <mat-icon aria-label="Employee icon-button with a add icon">add</mat-icon>
      </button>
    </div>
    <table mat-table [dataSource]="dataSource" class="example-table" >
      
        

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Navn </th>
          <td mat-cell *matCellDef="let element" (click)="editEmployee(element)" [style.color]="'navy'" [style.cursor] ="'pointer'"> {{element.name}} </td>
        </ng-container>
        
        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef> Mobilnummer </th>
          <td mat-cell *matCellDef="let element"> {{element.phone}}
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>
      
        
        <!--<ng-container matColumnDef="validate">
          <th mat-header-cell *matHeaderCellDef> Bekræftet </th>
          <td mat-cell *matCellDef="let element"> 
            <p *ngIf="element.shop_id_confirmed === '1';else noValidated"  [style.color]="'green'">Ja</p>
            <ng-template #noValidated><p [style.color]="'blue'">Nej</p></ng-template> 
          </td>
        </ng-container>
      -->
        <ng-container matColumnDef="authorize">
            <th mat-header-cell *matHeaderCellDef> Autoriseret</th>
            <td mat-cell *matCellDef="let element"> 
               <p *ngIf="element.shop_authorized === '1';else noAuthorized" [style.color]="'green'" >
                  Ja
               </p>
               <ng-template #noAuthorized><p [style.color]="'blue'">Nej</p></ng-template>
            </td>
          </ng-container>

        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef> Slet</th>
            <td mat-cell *matCellDef="let element"> 
              <!--<mat-slide-toggle [(ngModel)]="company.allow_ratings">Allow ratings and reviews?</mat-slide-toggle>-->
              <mat-icon (click)="delete(element)">delete</mat-icon>
            </td>
          </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <!--<div>
          <p>* Bemærk, hvis en person mangler, er det oftest fordi vedkommende ikke har fået bekræftet sit mobilnummer på sin profil i app'en.</p>
      </div>

      <div [hidden]="hasData">
        <p>There is no employee assign with present.</p>
        <p>To assign employees, please ask your employee to download "ShopEngros" app from appstore or google play.</p>
        <p>After downloading, during signup, they need to select their working place.</p>

      </div>

      <div>
        <p>Thank you for signing up with ShopEngros</p>
        <p>Team Engros</p>
      </div>-->

      
</div>
