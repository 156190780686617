import { Component, Inject, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef, MatSelectChange} from "@angular/material"
import {Employee} from "../shared/employee"
import {FormBuilder, Validators, FormGroup} from "@angular/forms"
import { EmployeeService } from '../services/employee.service'
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-employee-add',
  templateUrl: './employee-add.component.html',
  styleUrls: ['./employee-add.component.scss']
})
export class EmployeeAddComponent implements OnInit {

  browserLang: string
  formSubmitted = false
  form: FormGroup
  public event: EventEmitter<any> = new EventEmitter()

  /*genders=[
    {value: 'male'},
    {value: 'female'}
  ]*/
  genders = [
    {value: 'Mand'},
    {value: 'Kvinde'}
  ]

  countryCodes = [
    {value: '45', name: 'Danmark'},
    //{value: '46', name: 'Sweden'}
  ]

  jobtitles = [
    {value: 'Manager'},
    {value: 'Ejer'},
    {value: 'Ansat'}
  ]

  constructor(
    private employeeService: EmployeeService,
    private fb: FormBuilder,
    translate: TranslateService,
    private dialogRef: MatDialogRef<EmployeeAddComponent>,
    @Inject(MAT_DIALOG_DATA) employee:Employee) {

      this.browserLang = translate.getBrowserLang();
      

}

ngOnInit() {
  this.buildForm()
//this.setUserCategoryValidators()
}

isFieldInvalid(field: string) {
  return (!this.form.get(field).valid && this.form.get(field).touched);
}
buildForm() {
  this.form = this.fb.group({
    shop_id: localStorage.getItem('profile_id'),
    fname: ['', [Validators.required]],
    lname:[''],
    gender:['', [Validators.required]],
    country_with_dial_code: [this.countryCodes[0], [Validators.required]],
    country_code:[this.countryCodes[0].value],
    country:[this.countryCodes[0].name],
    phone: ['',[Validators.required]],
    email: ['',[Validators.required]],
    shop_id_confirmed: '1',
    job_title_confirmed: '1',
    shop_authorized: false,
    job_title: ['', Validators.required],
    locale: this.browserLang
});
}

setUserCategoryValidators() {
  const dialCodeControl = this.form.get('country_with_dial_code');
  const phoneControl = this.form.get('phone');
  const emailControl = this.form.get('email');

  if(phoneControl.valid){
    dialCodeControl.setValidators([Validators.required])
    //phoneControl.setValidators([Validators.required])
    emailControl.setValidators(null)
  }
  if(dialCodeControl.valid){
    phoneControl.setValidators([Validators.required])
    emailControl.setValidators(null)
  }
  if(emailControl.valid){
    phoneControl.setValidators(null)
    dialCodeControl.setValidators(null)
  }
  
  dialCodeControl.updateValueAndValidity();
  phoneControl.updateValueAndValidity();
  emailControl.updateValueAndValidity();
  console.log(phoneControl.valid)
  console.log(dialCodeControl.valid)
  console.log(emailControl.valid)
}

save() {
  event.preventDefault()
  //this.formSubmitted = true
  this.setUserCategoryValidators()
  console.log(this.form.valid);
    if (this.form.valid) {
      console.log(this.form.value); // Process your form
      
      //console.log(this.form.value)  
      this.form.value.country_code = this.form.value.country_with_dial_code.value
      this.form.value.country = this.form.value.country_with_dial_code.name
        this.event.emit({data: this.form.value});
        this.dialogRef.close();
          }
  
}


close() {
    this.dialogRef.close();
}

}
