<div fxLayout="column wrap" fxLayoutAlign="center center" class="content">
  <div class="login-image">
     <a href="https://shopengros.com"> <img mat-card-image src="/assets/imgs/logo.png" alt="logo"></a>
  </div>

<div class="signin-content">
    <mat-card>
        <mat-card-title>
            <h5 style="text-align:center; color:primary;">Velkommen kære butik</h5>
        </mat-card-title>
      <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <mat-form-field class="full-width-input">
            <input matInput placeholder="Email" formControlName="email" required>
            <mat-error *ngIf="isFieldInvalid('email')">
                Der skal indtastes email
            </mat-error>
          </mat-form-field>
          <!-- <p>
          <mat-form-field appearance="outline">
              <mat-label>Enter your password</mat-label>
            <input matInput type="password" placeholder="Password" formControlName="password" required>
            <mat-error *ngIf="isFieldInvalid('password')">
              Please inform your password
            </mat-error>
          </mat-form-field>
        </p> -->
          <mat-form-field class="full-width-input">
            <input matInput type="password" placeholder="Adgangskode" formControlName="password" required>
            <mat-error *ngIf="isFieldInvalid('password')">
                Der skal indtastes adgangskode
            </mat-error>
          </mat-form-field>
          
          <button mat-raised-button color="primary" type="submit">Login</button> 
        </form>
      </mat-card-content>
      <mat-card-footer>
          <a mat-button (click)="openForgetPasswordForm()" >Glemt adgangskode ?</a>
          <a mat-button routerLink="/signup">Opret en konto?</a>
          <br><br>
      </mat-card-footer>
    </mat-card>
    <div style="text-align:center; margin-top: 3%;">Copyright © {{today | date:'yyyy'}} ShopEngros ApS</div>
  </div>
</div>