
<div mat-dialog-content class="example-form">
    <h1 mat-dialog-title>Glemt adgangskode</h1>
    <form [formGroup]="resetPasswordForm" #fform="ngForm" (ngSubmit)="onSubmit()">
      <p>
        <mat-form-field class="full-width">
            <input matInput formControlName="email" placeholder="Email" type="email" email required>
            <!-- <mat-error *ngIf="feedbackForm.get('email').hasError('required') && feedbackForm.get('email').touched">Email ID is required</mat-error>-->
            <mat-error *ngIf="formErrors.email">{{ formErrors.email }}</mat-error>
        </mat-form-field>
      </p>
    <button mat-raised-button color="primary" [disabled] = resetPasswordForm.invalid>Nulstille adgangskode</button>
  </form>
</div>