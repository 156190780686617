import { Component, OnInit, Inject} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Shop } from '../shared/shop';
import { AuthService } from '../services/auth.service';
import{ SnackbarService } from '../services/snackbar.service';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  browserLang: string
  form: FormGroup
  private formSubmitAttempt: boolean
  shop: Shop
  submittingForm  : boolean=false
  submitted: boolean=false
  initForm: boolean = true


  constructor(
    private router: Router,
    private fb: FormBuilder,
    private snackbarService: SnackbarService,
    private authService: AuthService,
    public dialog: MatDialog,
    translate: TranslateService
  ) {
    this.browserLang = translate.getBrowserLang();
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone:['', Validators.required],
      address:[''],
      city:[''],
      zip:[''],
      cvr:'',
      web:['https://'],
      country:['Danmark'],
      contact_name:[''],
      contact_phone:[''],
      contact_email:[''],
      notes:'',
      agree:[false, Validators.required]
    });
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  onSubmit() {
    this.formSubmitAttempt = true;
    this.initForm = false
    this.submittingForm = true
    if (this.form.valid) {
      this.authService.signup(this.form.value).subscribe( 
        (shop) => {
          console.log(shop);
          if(shop.OK == "YES"){
            this.submitted = true
            this.submittingForm = false
            //this.snackbarService.success("Success..!!")
            //this.router.navigate(['/login']);
          }else{
            this.submittingForm = false
            this.initForm = true
            this.snackbarService.warn(shop.OK)
          }
        }, err => {
	      console.log(err);
        }
      )
    }
    this.form.reset 
  }

  showPrivacy(){
    this.authService.getPrivacy(this.browserLang).subscribe(
      data => {
        console.log(data)
        this.dialog.open(DialogSignupPrivacy, {
          width: '600px',
          disableClose: true ,
          data: data.privacy
        });
      }
    )
  }

  close(){
    this.router.navigate(['/login']);
  }
}

@Component({
  selector: 'dialog-signup-privacy',
  templateUrl: 'dialog-signup-privacy.html',
})
export class DialogSignupPrivacy {
  constructor(@Inject(MAT_DIALOG_DATA) public data: string) {}
}