<div fxLayout="column" fxLayoutAlign="center center" fxFlex="80" fxFlexOffset="10" fxFlex.xs="auto" fxFlexOffset.xs="0">
  <div class="image_logo">
    <img mat-card-image src="/assets/imgs/logo.png" alt="logo">
 </div>


  <div fxLayout="row wrap" fxLayoutAlign="center"  *ngIf="submittingForm">
      <h4>Venter ...</h4>
      <mat-spinner></mat-spinner>
    </div>
    <div fxLayout="column wrap" style="padding:15px; text-align: justify;" *ngIf="submitted">
      <mat-list>
        <h4>Tusinde tak for at have registreret din butik på vores ShopEngros markedsplads, vi vil kontakte dig i løbet af 1-3 arbejdsdage.</h4>
      <!--<h4>Thank you for signup to ShopEngros. We will contact you within 1-2 working days.</h4>-->
      <mat-list-item><button mat-raised-button (click)="close()">Go back</button></mat-list-item>
    </mat-list>

</div>
<div  fxFlex *ngIf="initForm">
    <mat-card class="signin-content">
      <mat-card-title>
        <h2 style="text-align:center;">Opret butiksprofil</h2>
      </mat-card-title>
      <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <p>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Navn</mat-label>
                  <input matInput type="text" formControlName="name" required>
                  <mat-error *ngIf="isFieldInvalid('name')">
                    Der skal indtastes et butiknavn
                     <!-- Navnet skal være mindst 4 tegn/  Please inform your company name -->
                    </mat-error>
                </mat-form-field>
            
              </p>
              <p>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>CVR</mat-label>
                  <input matInput type="text" formControlName="cvr" >
                  <mat-error *ngIf="isFieldInvalid('cvr')">
                      Der skal indtastes cvr
                      <!--Please inform your company cvr-->
                    </mat-error>
                </mat-form-field>
             
              </p>
              <div fxLayout="row wrap" fxLayout.xs="column" >
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Adresse</mat-label>
                <input matInput type="text" formControlName="address" >
                <mat-error *ngIf="isFieldInvalid('address')">
                    Der skal indtastes adresse
                  </mat-error>
              </mat-form-field>
              <span class="fill-space"></span>
              <mat-form-field appearance="outline" class="full-width" >
                <mat-label>Postnr</mat-label>
                <input matInput formControlName="zip" type="tel" pattern="[0-9]*" > 
                <mat-error *ngIf="isFieldInvalid('zip')">
                    Der skal indtastes postnummer
                    <!--Please inform postal code-->
                  </mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row wrap" fxLayout.xs="column" >
              <mat-form-field appearance="outline" class="full-width">
                  <mat-label>By</mat-label>
                  <input matInput type="text"formControlName="city" >
                  <mat-error *ngIf="isFieldInvalid('city')">
                      Der skal indtastes by
                    </mat-error>
                </mat-form-field>
                <span class="fill-space"></span>
                    <mat-form-field appearance="outline" class="full-width">
                      <mat-label>Land</mat-label>
                      <input matInput type="text" formControlName="country" >
                      <mat-error *ngIf="isFieldInvalid('country')">
                          Der skal indtastes et land
                        </mat-error>
                    </mat-form-field>
            
                  </div>
              <p>
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Hjemmeside</mat-label>
                <input matInput type="text" formControlName="web" >
              </mat-form-field>
            </p>
           
            <div fxLayout="row wrap" fxLayout.xs="column" >
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Telefon</mat-label>
                  <input matInput type="tel" pattern="[0-9]*" formControlName="phone" required>
                  <mat-error *ngIf="isFieldInvalid('phone')">
                      Der skal indtastes telefon
                    </mat-error>
                </mat-form-field>
                <span class="fill-space"></span>
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Email</mat-label>
                    <input matInput type="text" formControlName="email" required>
                    <mat-error *ngIf="isFieldInvalid('email')">
                        Der skal indtastes email
                      </mat-error>
                  </mat-form-field>
                </div>
                <p>
                      <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Kontaktperson navn</mat-label>
                        <input matInput type="text" formControlName="contact_name" >
                        <mat-error *ngIf="isFieldInvalid('contact_name')">
                            Der skal indtastes kontaktpersons navn
                          </mat-error>
                      </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field appearance="outline" class="full-width">
                          <mat-label>Kontaktperson email</mat-label>
                          <input matInput type="text" formControlName="contact_email" >
                          <mat-error *ngIf="isFieldInvalid('contact_email')">
                              Der skal indtastes kontaktperson email
                            </mat-error>
                        </mat-form-field>
                      </p>
                      <p>
                          <mat-form-field appearance="outline" class="full-width">
                            <mat-label>Kontaktperson mobil</mat-label>
                            <input matInput type="tel" pattern="[0-9]*" formControlName="contact_phone" >
                            <mat-error *ngIf="isFieldInvalid('contact_phone')">
                                Der skal indtastes kontaktperson mobil
                              </mat-error>
                          </mat-form-field>
                        </p>
                        <!--<p>
                            <mat-form-field appearance="outline" class="full-width">
                              <mat-label>Note</mat-label>
                              <textarea matInput formControlName="notes"></textarea>
                            </mat-form-field>
                          </p>-->
                          <p>
                            <mat-checkbox formControlName="agree" required></mat-checkbox>
                            <mat-label (click)="showPrivacy()" for="agree" style="margin-left:2%; cursor: pointer; color: blue;">Jeg accepterer vilkårene for brug, privatlivspolitik</mat-label>
                              
                            
                          </p>
        <!-- <mat-form-field class="full-width-input">
            <input matInput placeholder="Company Name" formControlName="name" required>
            <mat-error *ngIf="isFieldInvalid('name')">
              Please inform your company name
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <input matInput type="text" placeholder="Company Address" formControlName="address" required>
            <mat-error *ngIf="isFieldInvalid('address')">
              Please inform your company address
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <input matInput type="text" placeholder="City" formControlName="city" required>
            <mat-error *ngIf="isFieldInvalid('city')">
              Please inform city
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <input matInput type="text" placeholder="Country" formControlName="country" required>
            <mat-error *ngIf="isFieldInvalid('country')">
              Please inform country
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <input matInput type="text" placeholder="Company Email" formControlName="email" required>
            <mat-error *ngIf="isFieldInvalid('email')">
              Please inform your company email
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <input matInput type="text" placeholder="Company Phone" formControlName="phone" required>
            <mat-error *ngIf="isFieldInvalid('phone')">
              Please inform your company phone
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <input matInput type="text" placeholder="Company CVR" formControlName="cvr" required>
            <mat-error *ngIf="isFieldInvalid('cvr')">
              Please inform your company cvr
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <input matInput type="text" placeholder="Contact Person Name" formControlName="contact_name" required>
            <mat-error *ngIf="isFieldInvalid('contact_name')">
              Please inform contact person name
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <input matInput type="text" placeholder="Contact Person Email" formControlName="contact_email" required>
            <mat-error *ngIf="isFieldInvalid('contact_email')">
              Please inform contact person email
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <input matInput type="text" placeholder="Contact Person Phone" formControlName="contact_phone" required>
            <mat-error *ngIf="isFieldInvalid('contact_phone')">
              Please inform contact person phone
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <input matInput type="text" placeholder="Note" formControlName="note" >
          </mat-form-field> -->

        
        
      <!-- 
         
          <mat-form-field appearance="outline" class="full-width-input">
              <mat-label>Company Name</mat-label>
            <input matInput type="text" placeholder="Enter name here.." formControlName="name" required>
            <mat-error *ngIf="isFieldInvalid('name')">
                Please inform your company name
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width-input">
              <mat-label>Company Email</mat-label>
            <input matInput type="text" placeholder="Enter email here.." formControlName="email" required>
            <mat-error *ngIf="isFieldInvalid('email')">
                Please inform your company email
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width-input">
              <mat-label>Company Phone</mat-label>
            <input matInput type="text" placeholder="Enter phone number here.." formControlName="phone" required>
            <mat-error *ngIf="isFieldInvalid('phone')">
                Please inform your company telephone number
            </mat-error>
          </mat-form-field>
        -->
          
          <div fxLayout="row" fxLayoutAlign="space-between">
              <button mat-raised-button (click)="close()">Annullere</button>
              <button type="submit" mat-raised-button color = "primary" [disabled] = form.invalid>Gem og opret</button>
          </div>
        
        </form>
    
      </mat-card-content>
    </mat-card>
  </div>

  

</div>